import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { ALIGNMENTENUM } from "./../shared/widget.enums"

const ComponentCarouselWidget = (props) => {
    const [numberOfSteps, setNumbOfSteps] = useState(0);

    const ccwRef = useRef();

    const { attributes } = props;
    const { themes, component } = attributes;

    const buttonClassName = "position-absolute top-0px bottom-0px shadow-sm cursor-pointer text-26px text-bold m-auto rounded-circle width-50px height-50px bg-white d-flex justify-content-center align-items-center"


    const getCarouselObject = useCallback(() => {
        const viewPort = ccwRef.current.querySelector("div[aria-owns=\"carousel-viewport\"]");
        const carouselLeftButton = viewPort.querySelector("div[aria-owns=\"carousel-left-button\"]");
        const carouselRightButton = viewPort.querySelector("div[aria-owns=\"carousel-right-button\"]");
        const cardDeck = viewPort.querySelector("div[aria-owns=\"carousel-deck\"]");
        const cards = cardDeck.querySelectorAll("div[aria-owns=\"carousel-card\"]");

        cards.forEach(card => {
            card.classList.add("min-width-" + viewPort.offsetWidth + "px")
        });

        return {
            viewPort: viewPort,
            deck: cardDeck,
            leftButton: carouselLeftButton,
            rightButton: carouselRightButton,
            cards,
            slide: (limit) => {
                if (attributes?.position === ALIGNMENTENUM.LEFT) {
                    cardDeck.classList.remove("end--0px");
                    cardDeck.setAttribute("style", "right: " + (-limit) + "px");
                    cardDeck.classList.add("slide-right");
                } else {
                    cardDeck.classList.remove("start--0px");
                    cardDeck.setAttribute("style", "left: " + (-limit) + "px");
                    cardDeck.classList.add("slide-left");
                }
            },
            clearSelection: () => {
                cards.forEach(card => card.removeAttribute("aria-selected"));
            }
        };
    }, [attributes])

    useEffect(() => {
        const carousel = getCarouselObject();

        if (attributes?.position === ALIGNMENTENUM.LEFT) {
            // initialize deck
            carousel.deck.classList.add("end--0px");
            carousel.deck.classList.add("slide-right");

            // clear selection
            carousel.clearSelection();

            // set selected
            const selectedCard = carousel.deck.lastChild;
            selectedCard.setAttribute("aria-selected", "true");

            carousel.slide(0);

            // hide right button
            carousel.rightButton.classList.add("hide");
            carousel.leftButton.classList.remove("hide");

            // set number of steps to zero
            setNumbOfSteps(0);

        } else {
            // initialize deck
            carousel.deck.classList.add("start--0px");
            carousel.deck.classList.add("slide-left");

            // set selected
            const selectedCard = carousel.deck.firstChild;
            selectedCard.setAttribute("aria-selected", "true");

            // hide left button
            carousel.leftButton.classList.add("hide");
        }

        // set the number of steps
    }, [attributes, getCarouselObject])

    const handleLeftButton = (e) => {
        const carousel = getCarouselObject();
        let steps = numberOfSteps;

        if (carousel.cards.length > 0) {
            if (attributes?.position === ALIGNMENTENUM.LEFT) {
                const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");

                if (selectedCard.getAttribute("aria-colindex") > 0) {
                    steps += 1;
                    setNumbOfSteps(steps);

                    // get selected card width & margin
                    const cardStyle = getComputedStyle(carousel.cards[carousel.cards.length - 1].firstChild);
                    const actualCardWidth = carousel.cards[carousel.cards.length - 1].offsetWidth;
                    const actualCardMargin = parseInt(cardStyle.getPropertyValue("margin-left"));
                    let slideLimit = (actualCardWidth * steps); console.log(steps, selectedCard.getAttribute("aria-colindex"));

                    // clear selected card
                    selectedCard.removeAttribute("aria-selected");

                    // select the next card
                    const nextCard = selectedCard.previousSibling;
                    nextCard.setAttribute("aria-selected", "true");

                    // check if end of road
                    if (parseInt(selectedCard.getAttribute("aria-colindex")) <= 0) {
                        slideLimit -= (carousel.viewPort.offsetWidth - actualCardWidth + actualCardMargin);
                    }

                    // slide in card
                    carousel.slide(slideLimit);

                    // show right button
                    carousel.rightButton.classList.remove("hide");

                    // check if selected card is last on the list
                    if (parseInt(nextCard.getAttribute("aria-colindex")) === 0) {
                        carousel.leftButton.classList.add("hide");

                        // set number of steps to zero
                        setNumbOfSteps(0);
                    }
                }
            } else {
                const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");
                if (selectedCard.getAttribute("aria-colindex") > 0) {

                    steps += 1;
                    setNumbOfSteps(steps);

                    const style = getComputedStyle(carousel.deck);
                    const cardStyle = getComputedStyle(carousel.cards[carousel.cards.length - 1].firstChild);
                    const actualCardWidth = carousel.cards[carousel.cards.length - 1].offsetWidth;
                    const actualCardMargin = parseInt(cardStyle.getPropertyValue("margin-left"));
                    let slideLimit = Math.abs(parseInt(style.getPropertyValue("left")));

                    // get selected card width & margin
                    slideLimit -= actualCardWidth;

                    // clear selected card
                    selectedCard.removeAttribute("aria-selected");

                    // select the next card
                    const nextCard = selectedCard.previousSibling;
                    nextCard.setAttribute("aria-selected", "true");

                    // check if end of road
                    if ((carousel.cards.length - 1) === steps) {
                        slideLimit += (carousel.viewPort.offsetWidth - actualCardWidth + actualCardMargin);
                    }

                    // slide in card
                    carousel.slide(slideLimit);

                    // show right button
                    carousel.rightButton.classList.remove("hide");

                    // check if selected card is last on the list
                    if (parseInt(nextCard.getAttribute("aria-colindex")) === 0) {
                        carousel.leftButton.classList.add("hide");

                        // set number of steps to zero
                        setNumbOfSteps(0);
                    }
                }
            }
        }
    }

    const handleRightButton = () => {
        const carousel = getCarouselObject();
        let steps = numberOfSteps;

        if (carousel.cards.length > 0) {
            if (attributes?.position === ALIGNMENTENUM.LEFT) {
                const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");
                let steps = numberOfSteps;

                if (selectedCard.getAttribute("aria-colindex") < (carousel.cards.length - 1)) {

                    const style = getComputedStyle(carousel.deck);
                    const actualCardWidth = carousel.cards[carousel.cards.length - 1].offsetWidth;
                    let slideLimit = Math.abs(parseInt(style.getPropertyValue("right")));

                    // get selected card width & margin
                    slideLimit -= actualCardWidth;

                    // clear selected card
                    selectedCard.removeAttribute("aria-selected");

                    // select the next card
                    const nextCard = selectedCard.nextSibling;
                    nextCard.setAttribute("aria-selected", "true");

                    steps -= 1;
                    setNumbOfSteps(steps);

                    // show right button
                    carousel.leftButton.classList.remove("hide");

                    // check if selected card is last on the list
                    if (parseInt(nextCard.getAttribute("aria-colindex")) === (carousel.cards.length - 1)) {
                        carousel.rightButton.classList.add("hide");

                        // set number of steps to zero
                        setNumbOfSteps(0);
                    }

                    // slide in card
                    carousel.slide(slideLimit);
                }
            } else {
                const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");
                if (parseInt(selectedCard.getAttribute("aria-colindex")) < (carousel.cards.length - 1)) {

                    steps += 1;

                    const style = getComputedStyle(carousel.deck);
                    const cardStyle = getComputedStyle(carousel.cards[carousel.cards.length - 1].firstChild);
                    const actualCardWidth = carousel.cards[carousel.cards.length - 1].offsetWidth;
                    const actualCardMargin = parseInt(cardStyle.getPropertyValue("margin-left"));
                    let slideLimit = Math.abs(parseInt(style.getPropertyValue("left")));

                    // check if end of road
                    if ((carousel.cards.length - 1) === steps) {
                        slideLimit -= (carousel.viewPort.offsetWidth - actualCardWidth + actualCardMargin);
                    }

                    // get selected card width & margin
                    slideLimit += actualCardWidth;

                    // clear selected card
                    selectedCard.removeAttribute("aria-selected");

                    // select the next card
                    const nextCard = selectedCard.nextSibling;
                    nextCard.setAttribute("aria-selected", "true");

                    // slide in card
                    carousel.slide(slideLimit);

                    // set number of steps
                    setNumbOfSteps(steps);

                    // show right button
                    carousel.leftButton.classList.remove("hide");

                    // check if selected card is last on the list
                    if (parseInt(nextCard.getAttribute("aria-colindex")) === (carousel.cards.length - 1)) {
                        carousel.rightButton.classList.add("hide");

                        // set number of steps to zero
                        setNumbOfSteps(0);
                    }
                }
            }
        }
    }

    return (
        <div ref={ccwRef}>
            <div className={"position-relative overflow-hidden"}>
                <div aria-owns="carousel-viewport" className={themes.className + " position-relative max-width-1300px m-auto border-0px border-solid"}>
                    <div aria-owns="carousel-deck" className={"d-flex position-absolute top-0px bottom-0px"}>
                        {
                            attributes?.items.map((item, i) => {
                                const itemClassName = (i === 0) ? "ms-0 me-3" : ((i === attributes?.items.length - 1) ? "ms-0 me-0" : "ms-0 me-3")
                                return (
                                    <div aria-owns="carousel-card" aria-colindex={i} key={i} className={"d-flex w-100 text-decoration-none"}>
                                        <div className={itemClassName + " p-3 ps-4 pe-4 w-100 text-decoration-none bg-campilax-grey-theme"} to={item.href}>
                                            {component({
                                                attributes: (() => {
                                                    return {
                                                        themes: themes,
                                                        data: item
                                                    }
                                                })()
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div onMouseUp={e => { handleLeftButton(e) }} aria-owns="carousel-left-button" className={buttonClassName + " start--25px"}>
                        <BsChevronLeft />
                    </div>
                    <div onMouseUp={e => { handleRightButton(e) }} aria-owns="carousel-right-button" className={buttonClassName + " end--25px"}>
                        <BsChevronRight />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ComponentCarouselWidget