import React, { useEffect } from "react";
import CaptionWidget from "../../shared/widgets/caption/caption.widget";
import PageFooterWidget from "../../shared/widgets/page.footer/page.footer.widget";
import HomePageHeaderWidget from "../../shared/widgets/page.header/homepage.header.widget";
import CardGridCarouselWidget from "../../shared/widgets/card.grid.carousel/card.grid.carousel.widget";
import BannerWidget from "../../shared/widgets/banner/banner.widget";
import ComponentCarouselWidget from "../../shared/widgets/component.carousel/component.carousel.widget";
import { GiConvergenceTarget } from "react-icons/gi";
import { SiMinds } from "react-icons/si"
import { FaBoxOpen } from "react-icons/fa"
import CardWidget from "../../shared/widgets/card/card.widget";
import CaptionCardGridWidget from "../../shared/widgets/caption.card.grid/caption.card.grid.widget";
import { AiOutlineArrowRight } from "react-icons/ai";
import { MdArrowForwardIos } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { ALIGNMENTENUM } from "../../shared/widgets/shared/widget.enums";
import { BsTelephoneFill } from "react-icons/bs";
import ItemListWidget from "../../shared/widgets/item.list/item.list.widget";


const HomeComponent = () => {

    useEffect(() => {
        //
    }, [])

    const getHeaderSectionDetails = () => {
        return {
            header: {
                leftPane: {
                    menu: [
                        {
                            text: "Why Campilax",
                            href: "#",
                            component: ItemListWidget,
                            data: {
                                themes: {
                                    icon: "width-60px height-60px",
                                    title: "text-14px text-bold text-campilax-blue-theme- mb-1",
                                    description: "text-13px",
                                    className: "pt-1 pb-1 text-13px"
                                },
                                items: [
                                    {
                                        href: "/services",
                                        title: "Small & enterprise solutions",
                                        description: "Take your content further and stand out with the next-level power of Premiere Pro.",
                                        links: [
                                            {
                                                text: "Learn more",
                                                href: ""
                                            }
                                        ]
                                    },
                                    {
                                        href: "/industries",
                                        title: "Healthcare Solutions",
                                        description: "With the Acrobat Reader mobile app, your smartphone is now a serious business tool.",
                                        links: [
                                            {
                                                text: "Learn more",
                                                href: ""
                                            }
                                        ]
                                    },
                                    {
                                        href: "/business-solutions",
                                        title: "Financial solutions",
                                        description: "With the Acrobat Reader mobile app, your smartphone is now a serious business tool.",
                                        links: [
                                            {
                                                text: "Learn more",
                                                href: ""
                                            }
                                        ]
                                    },
                                    {
                                        href: "/our-work",
                                        title: "Educational Solutions",
                                        description: "With the Acrobat Reader mobile app, your smartphone is now a serious business tool.",
                                        links: [
                                            {
                                                text: "Learn more",
                                                href: ""
                                            }
                                        ]
                                    }
                                ]
                            }
                        },
                        {
                            text: "Products",
                            href: "#",
                            component: ItemListWidget,
                            data: []
                        },
                        {
                            text: "Support Center",
                            href: "/business-solutions"
                        }
                    ]
                },
                rightPane: {
                    // menu: [
                    //     {
                    //         text: "Login",
                    //         href: "#",
                    //         items: []
                    //     },
                    //     {
                    //         text: "Signup",
                    //         href: "/business-solutions"
                    //     }
                    // ],
                    button: [
                        {
                            logo: <BsTelephoneFill />,
                            text: "Contact sales",
                            action: () => { },
                            className: "btn-campilax-blue-opac1-theme text-15px text-campilax-blue-theme rounded-pill text-bold"
                        }
                    ]
                },
                className: "pt-2 pb-2"
            },
            banner: {
                themes: {
                    title: "text-59px mb-4",
                    description: "text-16px mb-4",
                },
                data: {
                    title: "Welcome to a world of innovative ideas & possibilities.",
                    description: "We help our customers better manage their web presence in order to achieve greater success online."
                },
                buttons: [
                    {
                        text: "Learn more",
                        icon: <MdArrowForwardIos />,
                        action: () => { alert("Hey cliker") },
                        className: "btn-campilax-yellow-theme me-3 text-15px text-bold rounded-pill"
                    },
                ]
            }
        };
    }

    const getOurServices = () => {
        return {
            captionWidget: {
                themes: {
                    caption: "manrope-regular text-16px text-bold text-campilax-blue-theme mb-4",
                    title: "max-width-800px mx-auto text-38px text-bold mb-4",
                    description: "w-50 mx-auto text-14px mb-4",
                    className: "text-center mx-auto"
                },
                data: {
                    caption: "Services we provide",
                    title: "Your small business technology solution",
                    description: "We are an insight and behavior driven creative marketing agency. A Full Service Digital Creative Agency Specializing in: Video Production, Web Design, Branding, Brand Strategy"
                },
            },
            categories: {
                themes: {
                    media: "bg-campilax-blue-theme text-20px height-200px w-100",
                    title: "text-18px text-bold text-campilax-blue-theme- mb-2",
                    description: "text-14px",
                    className: "min-height-400px pt-3 pb-3"
                },
                items: [
                    {
                        media: {
                            src: null,
                            banner: "banner-10"
                        },
                        href: "/services",
                        title: "Small & enterprise solutions",
                        description: "Take your content further and stand out with the next-level power of Premiere Pro.",
                        links: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-7"
                        },
                        href: "/industries",
                        title: "Healthcare Solutions",
                        description: "With the Acrobat Reader mobile app, your smartphone is now a serious business tool.",
                        links: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-3"
                        },
                        href: "/business-solutions",
                        title: "Financial solutions",
                        description: "With the Acrobat Reader mobile app, your smartphone is now a serious business tool.",
                        links: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-12"
                        },
                        href: "/our-work",
                        title: "Educational Solutions",
                        description: "With the Acrobat Reader mobile app, your smartphone is now a serious business tool.",
                        links: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    }
                ]
            }
        }
    }

    const getAboutSection = () => {
        return {
            items: [
                {
                    themes: {
                        caption: "text-16px text-dark text-bold text-campilax-blue-theme mb-4",
                        title: "text-38px text-dark text-bold mb-4",
                        description: "text-14px line-height-23px text-dark mb-4",
                    },
                    data: {
                        caption: "Who we are",
                        title: "A complete technology solution provider",
                        description: "We are a Software Company passionate about delivering top-notch IT solutions and services to meet the ever-changing need of businesses/organizations. With over (input here) years of industrial base experience, we help design, build, and scale up your software needs."
                    },
                    buttons: [
                        {
                            text: "Learn more",
                            icon: <MdArrowForwardIos />,
                            action: () => { alert("Hey cliker") },
                            className: "btn-campilax-yellow-theme me-3 text-14px text-bold rounded-pill"
                        }
                    ]
                },
                {
                    themes: {
                        icon: "mb-3 text-40px text-campilax-blue-theme",
                        title: "text-16px text-campilax-blue-theme text-bold mb-2",
                        description: "text-14px line-height-23px text-dark",
                    },
                    data: {
                        icon: <GiConvergenceTarget />,
                        title: "Mission statement",
                        description: "Developing effective and efficient technological solutions, and services geared toward maximizing the productivity of every business/organization."
                    }
                },
                {
                    themes: {
                        icon: "mb-3 text-40px text-campilax-blue-theme",
                        title: "text-16px text-campilax-blue-theme text-bold mb-2",
                        description: "text-14px line-height-23px text-dark",
                    },
                    data: {
                        icon: <SiMinds />,
                        title: "Our vision",
                        description: "To provide reliable, dynamic, and scalable technological solutions and services to the growing needs of businesses."
                    }
                }
            ]
        }
    }

    const getOurProducts = () => {
        return {
            captionWidget: {
                themes: {
                    caption: "manrope-regular text-16px text-bold text-campilax-blue-theme mb-4",
                    title: "max-width-600px text-38px text-bold",
                    description: "w-50 mx-auto text-14px",
                    className: "text-center- mx-auto"
                },
                data: {
                    caption: "Our products",
                    title: "Explore resources to unleash the potential of your team",
                    // description: "We are an insight and behavior driven creative marketing agency. A Full Service Digital Creative Agency Specializing in: Video Production, Web Design, Branding, Brand Strategy"
                },
            },
            contentWidget: {
                themes: {
                    className: "min-height-500px",
                    media: "bg-campilax-blue-theme text-20px height-600px- w-100",
                    title: "text-18px text-bold mb-2",
                    description: "text-14px",
                    component: "pt-3 pb-3"
                },
                items: [
                    {
                        media: {
                            src: null,
                            banner: "banner-3"
                        },
                        href: "/business-solutions",
                        title: "UnBankd",
                        description: "With the Acrobat Reader mobile app, your smartphone is now a serious business tool."
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-1"
                        },
                        href: "/services",
                        title: "StoreFront",
                        description: "Take your content further and stand out with the next-level power of Premiere Pro."
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-1"
                        },
                        href: "/services",
                        title: "LocaMarket",
                        description: "Take your content further and stand out with the next-level power of Premiere Pro."
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-1"
                        },
                        href: "/services",
                        title: "KrediCard",
                        description: "Take your content further and stand out with the next-level power of Premiere Pro."
                    },
                    // {
                    //     media: {
                    //         src: null,
                    //         banner: "banner-1"
                    //     },
                    //     href: "/services",
                    //     title: "kaggoe",
                    //     description: "Take your content further and stand out with the next-level power of Premiere Pro."
                    // },
                    // {
                    //     media: {
                    //         src: null,
                    //         banner: "banner-1"
                    //     },
                    //     href: "/services",
                    //     title: "Erie",
                    //     description: "Take your content further and stand out with the next-level power of Premiere Pro."
                    // }
                ],
                component: CardWidget,
                position: ALIGNMENTENUM.LEFT
            }
        }
    }

    const getBlogsAndEvents = () => {
        return {
            captionWidget: {
                themes: {
                    caption: "manrope-regular text-16px text-bold text-white mb-4",
                    title: "max-width-600px text-38px text-bold text-white",
                    description: "w-50 mx-auto text-14px text-white",
                    className: "text-center- mx-auto"
                },
                data: {
                    caption: "Blogs & Events",
                    title: "Get the insights to stay ahead of change",
                },
            },
            contentWidget: {
                themes: {
                    className: "min-height-700px",
                    media: "bg-campilax-blue-theme text-20px height-600px- w-100",
                    title: "text-18px text-bold mb-2",
                    description: "text-14px",
                    component: "pt-3 pb-3"
                },
                items: [
                    {
                        media: {
                            src: null,
                            banner: "banner-3"
                        },
                        href: "/business-solutions",
                        title: "Hear from today’s fearless builders and innovators",
                        description: "With the Acrobat Reader mobile app, your smartphone is now a serious business tool."
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-1"
                        },
                        href: "/services",
                        title: "StoreFront",
                        description: "Take your content further and stand out with the next-level power of Premiere Pro."
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-1"
                        },
                        href: "/services",
                        title: "What's trending",
                        description: "Take your content further and stand out with the next-level power of Premiere Pro."
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-1"
                        },
                        href: "/services",
                        title: "Join the train",
                        description: "Take your content further and stand out with the next-level power of Premiere Pro."
                    }
                ],
                component: CardWidget,
                position: ALIGNMENTENUM.RIGHT
            }
        }
    }

    const getReadySection = () => {
        return {
            items: [
                {
                    themes: {
                        title: "text-28px text-dark text-bold mb-4",
                        description: "text-17px line-height-28px text-dark mb-4",
                    },
                    data: {
                        title: "Ready to get your business started?",
                        description: "Explore our product store to shop for the best software solution for your business. You can also contact us to design a custom solution for your business."
                    },
                    buttons: [
                        {
                            text: "Start now",
                            action: () => { },
                            className: "btn-campilax-yellow-theme rounded-pill me-3 text-14px text-bold"
                        }
                    ],
                    links: [
                        {
                            text: "Contact sales",
                            icon: <AiOutlineArrowRight />,
                            href: "",
                            className: "me-3"
                        }
                    ]
                },
                {
                    themes: {
                        icon: "mb-3 text-40px text-campilax-blue-theme",
                        title: "text-15px text-campilax-dark-theme text-bold mb-2",
                        description: "text-14px line-height-22px text-dark mb-2",
                    },
                    data: {
                        icon: <FaBoxOpen />,
                        title: "Easy-to-use tools for your businesses",
                        description: "Effective and efficient software solutions geared toward maximizing the productivity of every business."
                    },
                    links: [
                        {
                            text: "Learn more",
                            icon: <MdArrowForwardIos />,
                            href: "",
                            className: "text-13px me-3"
                        }
                    ]
                },
                {
                    themes: {
                        icon: "mb-3 text-40px text-campilax-blue-theme",
                        title: "text-15px text-campilax-dark-theme text-bold mb-2",
                        description: "text-14px line-height-22px text-dark mb-2",
                    },
                    data: {
                        icon: <BiSupport />,
                        title: "Get all the support you need",
                        description: "To provide reliable, dynamic, and scalable technological solutions and services to the growing needs of businesses."
                    },
                    links: [
                        {
                            text: "Learn more",
                            icon: <MdArrowForwardIos />,
                            href: "",
                            className: "text-13px"
                        }
                    ]
                }
            ]
        }
    }

    return (
        <div>
            {/* header section */}
            <div>
                <HomePageHeaderWidget attributes={getHeaderSectionDetails()} />
            </div>

            {/* body section */}
            <div className={""}>

                {/* section 1 */}
                <div className={"mx-auto pt-0 pb-5"}>
                    <div className={"max-width-1300px mx-auto"}>
                        <div className={"mt-5 mb-3"}>
                            <CaptionWidget attributes={getOurServices().captionWidget}></CaptionWidget>
                        </div>
                        <CardGridCarouselWidget attributes={getOurServices().categories} />
                    </div>
                </div>

                {/* section 2 */}
                <div className={"position-relative mx-auto pt-2 pb-5"}>
                    <div className={"position-absolute top-0px end-0px bottom-0px start-0px overflow-hidden"}>
                        <div className={"position-relative height-100 bg-campilax-blue-theme opacity-1"}></div>
                    </div>
                    <div className={"d-flex w-100 max-width-1300px height-700px mx-auto pt-0 pb-5 mt-5"}>
                        <BannerWidget attributes={getAboutSection()} />
                    </div>
                </div>

                {/* separator section */}
                <div className={"position-relative mx-auto pt-5 pb-0"}>
                    <div className={"position-absolute top--100px end-0px bottom-0px start-0px overflow-hidden"}>
                        <div className={"position-relative height-80px skew--3deg bg-white"}>
                            <div className={"position-absolute top-0px start-0px- bottom-0px end-0px d-flex width-400px height-30px"}>
                                <div className={"position-absolute top--20px start-0px bottom-0px bg-campilax-green-theme width-60px height-50px z-index-0"}></div>
                                <div className={"position-absolute top-0px start-0px bottom-0px bg-campilax-blue-theme width-200px height-30px z-index-1"}></div>
                                <div className={"position-absolute top-0px end--20px bottom-0px bg-campilax-blue-theme width-200px height-30px z-index-1"}></div>
                                <div className={"position-absolute top-0px end-0px bottom--20px bg-campilax-yellow-theme width-60px height-50px"}></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* section 3 */}
                <div className={"position-relative mx-auto pt-0 pb-5"}>
                    <div className={"position-absolute top--100px end-0px bottom-0px start-0px overflow-hidden"}>
                        <div className={"position-relative height-70px skew--3deg bg-white"}></div>
                    </div>
                    <div className={"min-height-600px pt-0 pb-5 mt-0"}>
                        <div className={"position-relative max-width-1300px mx-auto mb-4"}>
                            <CaptionWidget attributes={getOurProducts().captionWidget}></CaptionWidget>
                        </div>
                        <ComponentCarouselWidget attributes={getOurProducts().contentWidget} />
                    </div>
                </div>

                {/* section 4 */}
                <div className={"position-relative mx-auto pt-5 pb-5"}>
                    <div className={"position-absolute top-0px end-0px bottom-0px start-0px overflow-hidden"}>
                        <div className={"position-relative height-100 bg-campilax-blue-opac1-theme"}></div>
                    </div>
                    <div className={"min-height-700px pt-0 pb-5 mt-0"}>
                        <div className={"position-absolute start-0px end-0px max-width-1300px mx-auto"}>
                            {/* <CaptionCardGridWidget attributes={getReadySection()} /> */}
                            <CaptionWidget attributes={getOurServices().captionWidget}></CaptionWidget>
                        </div>
                        {/* <div className={"mt-5 mb-3"}>
                        </div> */}
                        {/* <CardGridCarouselWidget attributes={getOurServices().categories} /> */}
                    </div>
                </div>

                {/* section 5 */}
                <div className={"position-relative mx-auto pt-0 pb-5"}>

                    {/* separator section */}
                    <div className={"position-relative mx-auto pt-5 pb-0"}>
                        <div className={"position-absolute top--100px end-0px bottom-0px start-0px overflow-hidden z-index-1"}>
                            <div className={"position-relative height-100px skew--3deg bg-campilax-blue-theme"}></div>
                        </div>
                    </div>

                    {/* bg layer */}
                    <div className={"position-absolute top-0px end-0px bottom-0px start-0px overflow-hidden"}>
                        <div className={"position-relative height-100 bg-campilax-blue-theme opacity-10"}></div>
                    </div>

                    <div className={"min-height-400px pt-0 pb-5 mt-0"}>
                        <div className={"position-relative max-width-1300px mx-auto mb-4"}>
                            <CaptionWidget attributes={getBlogsAndEvents().captionWidget}></CaptionWidget>
                        </div>
                        <ComponentCarouselWidget attributes={getBlogsAndEvents().contentWidget} />
                    </div>
                </div>

                {/* section 6 */}
                <div className={"position-relative mx-auto pt-5 pb-5"}>
                    <div className={"min-height-460px pt-0 pb-5 mt-0"}>
                        <div className={"position-absolute start-0px end-0px max-width-1300px mx-auto"}>
                            <CaptionCardGridWidget attributes={getReadySection()} />
                        </div>
                    </div>
                </div>

            </div>

            {/* footer section */}
            <div className={"min-height-200px"}>
                <PageFooterWidget />
            </div>
        </div>
    )
}

export default HomeComponent;